import React, { useEffect } from "react";

import styled from "styled-components";
import HTMLReactParser from "html-react-parser";

import { breakpoints } from "utils/mixins";
import { green } from "theme/colors";

//STYLES
const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.dataColor};
  color: ${(props) => props.dataText};
  .container {
    padding: 0 20px;
    margin: 80px auto;
    position: relative;
    h1 {
      font-size: calc(2.1vw + 10px);
      margin-top: calc(60px + 2%);
      color: ${green.default};
      text-align: left;
    }
    .brands {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      border-top: 1px solid ${green.default};
      border-bottom: 1px solid ${green.default};
      padding: 4% 0;
      margin: 4% 0;
      &--nob {
        border: none;
      }
      .brand {
        margin: 20px;
        img {
          display: block;
          max-width: 40vw;
          //max-height: 70px;
          height: auto;
        }
      }
    }
  }
  @media ${breakpoints.laptop} {
    .container {
      margin: 120px auto;
      .brands {
        .brand {
          img {
            max-width: 225px;
          }
        }
      }
    }
  }
  @media (min-width: 1921px) {
    .container {
      margin: 100px 15%;
    }
  }
`;
//STYLES

const Policy = (props) => {
  //PROPS
  const {
    dimensions,
    setColor,
    data: {
      page: {
        brands: { brands, pubBrands, introtext }
      }
    }
  } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    setColor(true);
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper dataColor={"white"} dataText={"black"} className="faq-wrapper">
      <div className="container">
        {introtext && HTMLReactParser(introtext)}
        <ul className="brands">
          {brands.map((brand, index) => {
            //   console.log(brand);
            return (
              <li key={index} className="brand">
                <img
                  alt={brand.altText}
                  src={brand.localFile?.childImageSharp?.fluid?.src}
                />
              </li>
            );
          })}
        </ul>
        <ul className="brands brands--nob">
          {pubBrands.map((brand, index) => {
            return (
              <li key={index} className="brand">
                <img
                  alt={brand.altText}
                  src={brand.localFile?.childImageSharp?.fluid?.src}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  query Parceiros($id: String) {
    page: wpPage(id: { eq: $id }) {
      brands {
        brands {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
              }
            }
          }
        }
        introtext
        pubBrands {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default Policy;
